import React from 'react'
import './Card1.css'
const  Card1 = () => {
  return (
    <div className="card-container">
        <div className='card'>
          <div className="card-desc">
            <h2 className="card-title">DELUXE ROOM</h2>
            <a href="">BOOK NOW</a>
          </div>
        </div>
        <div className='card'>
        <div className="card-desc">
            <h2 className="card-title">SPACIAOUS LOBBY</h2>
            <a href="">BOOK NOW</a>
          </div>
        </div>
        <div className='card'>
        <div className="card-desc">
            <h2 className="card-title">STRATEGIC LOCATION</h2>
            <a href="">BOOK NOW</a>
          </div>
        </div>
    </div>
  )
}

export default Card1