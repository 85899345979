
// import Navbar from './components/Navbar';
import Navbar from './components/Navbar/Navbar';
import Paralax from './components/Paralax/Paralax';
import Section from './components/Section/Section';
import Footer from './components/Footer/Footer'
import Map from './components/Map/Map'
function App() {
  return (
    <div>
      <Navbar />
      <Paralax />
      <Section />
      <Map />
      <Footer />
    </div>
  );
}

export default App;
