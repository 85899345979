
import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'


import './Navbar.css'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    //change nav color when scrolling
    const [color, setColor] = useState(false)
    const changeColor = () => {
      if(window.scrollY >=90) {
        setColor(true)
      } else {
        setColor(false)
      }
    }
    window.addEventListener('scroll',changeColor)


    const closeMenu = () => setClick(false)

    return (
        <div className={color ? 'header header-bg' : 'header'}>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    <h1>Pondok Dukuh</h1>
                </a>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (null)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (null)}
                </div>

                    <li className='nav-item'>
                        <a href='#rooms' onClick={closeMenu}><h4>Rooms</h4></a>
                    </li>
                    <li className='nav-item'>
                        <a href='#gallery' onClick={closeMenu}><h4>Gallery</h4></a>
                    </li>
                    <li className='nav-item'>
                        <a href='#cars' onClick={closeMenu}><h4>Cars</h4></a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar