import React from 'react'
import './Map.css'
const Map = () => {
  return (
    <div className='map'>
      <div className="section-title">
          <h1>MAPS</h1>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1657.7391769343947!2d115.19537518755143!3d-8.80574783634043!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2458d0db044a1%3A0x319dd97a38737042!2sOYO%2090690%20Pondok%20Dukuh!5e0!3m2!1sen!2sus!4v1697287880963!5m2!1sen!2sus" width="95%" height="300"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  )
}

export default Map