import React from 'react'
import './Footer.css'

import { FaInstagram, FaWhatsapp, FaFacebookSquare, FaTiktok} from 'react-icons/fa';
import { AiOutlineMail} from 'react-icons/ai';
import { HiOutlineLocationMarker} from 'react-icons/hi';


const Footer = () => {
  return (
    <div className="footerContainer">
        <div className="footerContent">
            <div className="footerLogo">
                <h1>Pondok Dukuh</h1>
                <ul>
                    <li><p>Pondok Dukuh is a budget hotel in Bali that offers a standardised level of amenities at the best value. </p> </li>
                </ul>
            </div>
            <div className="footerAbout">
                <h3>About Us</h3>
                <ul>
                    <li>Inn</li>
                    <li>Car Rental</li>
                    <li>Hotel</li>
                </ul>
            </div>
            <div className="footerSearch">
                <h3>Navigate</h3>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="#rooms">Room</a></li>
                    <li><a href="#gallery">Gallery</a></li>
                    <li><a href="#cars">Cars</a></li>
                </ul>
            </div>
            <div className="footerTouch">
                <ul>
                    <li>
                        <h5><AiOutlineMail />Email</h5>
                        <p>pondokdukuh.@gmail.com</p>
                    </li>
                    <li>
                        <h5><FaWhatsapp /> WhatsApp</h5>
                        <p>+62 812-999-999</p>
                    </li>
                    <li>
                        <h5><HiOutlineLocationMarker /> Alamat</h5>
                        <p>Jl. Lorem ipsum dolor sit amet.- Bali</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer