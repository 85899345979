import { Parallax } from 'react-parallax';
import img1 from '../../assets/1.png'
import './Paralax.css'
const Paralax = () => {
  return (
    <div className="paralax-container">
      <div className="content-pc">

        <Parallax className='paralax' blur={3} bgImage={img1}  strength={400}>
            <div className="content">
              <div className="img-txt">

                <h1>Welcome To Pondok Dukuh Inn and Car Rental</h1>
              </div>
            </div>
        </Parallax>
      </div>
      <div className="content-mobile">
        <img src={img1} alt="" />
      </div>
    </div>
  )
}

export default Paralax