export const Media = [
    {type: 'image', url: require('../../assets/0.png')},
    {type: 'image', url: require('../../assets/1.png')},
    {type: 'image', url: require('../../assets/2.png')},
    {type: 'image', url: require('../../assets/3.png')},
    {type: 'image', url: require('../../assets/4.png')},
    {type: 'image', url: require('../../assets/5.png')},
    {type: 'image', url: require('../../assets/6.png')},
    {type: 'image', url: require('../../assets/7.png')},
    {type: 'image', url: require('../../assets/8.png')},
    {type: 'image', url: require('../../assets/9.png')},
    {type: 'image', url: require('../../assets/10.png')},
    {type: 'image', url: require('../../assets/11.png')},
    {type: 'image', url: require('../../assets/12.png')},
    {type: 'image', url: require('../../assets/13.png')},
    {type: 'image', url: require('../../assets/14.png')},
    {type: 'image', url: require('../../assets/15.png')},
    {type: 'image', url: require('../../assets/16.png')},
    {type: 'image', url: require('../../assets/17.png')},
    {type: 'image', url: require('../../assets/18.png')},
    {type: 'image', url: require('../../assets/19.png')},


]