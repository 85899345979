import React, { useEffect, useRef } from 'react';
import "./Section.css"
import ImageSlideShow from '../Card/ImageSlideShow'
import Card1 from '../Card/Card1'
import Card2 from '../Card/Card2'
import Gallery from '../Gallery/Gallery'
import ac from '../../assets/icons/ac.png'
import tv from '../../assets/icons/tv.png'
const Section = () => {




  return (
    <div className="section container">


        <div  className="section-title py-5 " id = "rooms">
            <h1>Pondok Dukuh</h1>
            <p>7, Jl. Dukuh Sari II No.18, Benoa, Kec. Kuta Sel., Kabupaten Badung, Bali 80361, Bali</p>
        </div>
        <div className="flex even g-5 p-2">
          <div className="section-image">
            <ImageSlideShow />
          </div>
          <div className="section-content">
            <div className="section-description">
                <h2>Description</h2>
                <p>Pondok Dukuh is a budget hotel in Bali that offers a standardised level of amenities at the best value. The hotel is located 32 km from Tanah Lot and 2 km from Garuda Wisnu Kencana Cultural Park.</p>

                <h3>Special Features</h3>
                <p><img src={ac} alt="" className='icon'/> AC</p>
                <p><img src={tv} alt="" className='icon-2'/> TV</p>

                <h3>Location & Transportation</h3>
                <p>The property is well connected to nearby popular attractions, such as Kuta Beach(12 km), Uluwatu Temple(9 km), and Sacred Monkey Forest Sanctuary(40 km).</p>
                <p>★ Pondok Dukuh has been delighting guests since 30 Sep 2021.</p>

                <a href="https://wa.me/6282145234468?text=Hi%20I'm%20interested%20to%20book%20your's%20hotel" className='section-button'>
                  <h3>Book Now</h3>
                </a>
            </div>
          </div>
        </div>

    
          <div  className="section-title py-5">
              <h1>ACCOMODATIONS</h1>
          </div>
          <Card1 />


          <div  className="section-title py-5" id = "gallery">
              <h1>GALLERY</h1>
          <p></p>
          </div>
          <Gallery />
          


          <div className="section-title py-5" id = "cars">
              <h1>CAR RENTAL</h1>
          <p>List of cars that can be rented by Pondok Dukuh Rent Car which are in great demand by domestic and foreign tourists. Starting from automatic and manual cars, good for families and individuals.</p>
          </div>
          <Card2 />


    </div>
  )
}

export default Section