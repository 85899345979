import React from 'react'
import './Card2.css'

var Cars = [
    {
        id:1,
        title: 'ALPHARD',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/alphard.jpg'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20ALPHARD"
    }, 
    {
        id:2,
        title: 'TERIOS',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/terios.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20TERIOS"

    },
    {
        id:3,
        title: 'VELOZ',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/veloz.jpg'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20VELOZ"

    },
    {
        id:4,
        title: 'INOVA',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"

    },
    {
        id:5,
        title: 'MOBILBARU',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"

    },
    {
        id:6,
        title: 'LOREM',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"

    },
    {
        id:7,
        title: 'IPSUM',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"
    },
    {
        id:8,
        title: 'IPSUM',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"

    },
    {
        id:9,
        title: 'IPSUM',
        price: '250.000',
        price2: '500.000',
        image: require('../../assets/mobil/inova.png'),
        link: "https://wa.me/6282145234468?text=I'm%20interested%20to%20rent%20your's%20INOVA"

    },

  ]
const Card2 = () => {
  return (
    <div className='card2-container'>
        <div className="card2-wrapper g-5">
            {
                Cars.map(car => {
                return (

                    <div className='card2' key={car.id}>
                        <img src={car.image} className='card2-image' alt="" />
                        <div className="card2-desc">
                            <h4>{car.title}</h4>
                            <h4>Rp. {car.price}/24jam</h4>
                            <p>Detail Harga Sewa</p>
                            <p>Lepas Kunci: Rp. {car.price}/24jam</p>
                            <p>Sopir + BBM: Rp. {car.price2}/24jam</p>
                        </div>
                        <a href={car.link} className='card2-button'><h4>RENT NOW</h4></a>
                    </div>
                )
                })
            }
            
        </div>

        


    </div>
  )
}

export default Card2